import { ConsoleOutputLevel } from "../@types"

export default function (
  allowedLogLevel: string,
  messageLogLevel: ConsoleOutputLevel,
) {
  const toLower = allowedLogLevel.toLowerCase()
  return toLower === "error"
    ? messageLogLevel === ConsoleOutputLevel.error
    : toLower === "warn"
      ? messageLogLevel === ConsoleOutputLevel.error ||
        messageLogLevel === ConsoleOutputLevel.warn
      : toLower === "info"
        ? messageLogLevel === ConsoleOutputLevel.error ||
          messageLogLevel === ConsoleOutputLevel.warn ||
          messageLogLevel === ConsoleOutputLevel.info
        : toLower === "debug"
          ? messageLogLevel === ConsoleOutputLevel.error ||
            messageLogLevel === ConsoleOutputLevel.warn ||
            messageLogLevel === ConsoleOutputLevel.info ||
            messageLogLevel === ConsoleOutputLevel.debug
          : false
}
