import { HasValue, OutputToConsoleFunc, SessionMetadata } from "../@types"
import { ApplicationEvents } from "./applicationEvents"
import { TestObject3 } from "./config3"
import makeExecutor from "./makeExecutor"
import { Scheduler } from "./measurementScheduler"
import { PerformanceEntryManager } from "./openinsights/resourceTiming"
import { PageSettings } from "./pageSettings"
import { createPlatformTestObject } from "./platformTestObject"
import { ResettableEventCounter } from "./resettableEventCounter"
import { RuntimeValues } from "./runtimeValues"
import { selectWeightedRandomSample } from "./selectRandomSample"
import { SessionConfig } from "./sessionConfig"

export interface Dependencies {
  applicationEvents: ApplicationEvents
  callClearTimeout: (depID: number, timeoutID: number) => void
  callFetch: (url: string, options?: RequestInit) => Promise<Response>
  callSendBeacon: (depID: number, url: string) => boolean
  callSetTimeout: (depID: number, fn: () => void, timeout: number) => number
  callClearResourceTimings: () => void
  maxTargetFrequency: HasValue<number>
  measurementScheduler: Scheduler
  newAbortController: () => AbortController
  newDate: (depID: number) => Date
  newEpochTimestamp: (depID: number) => number
  newMonotonicTimestamp: (depID: number) => number
  newRandomNumber: (depID: number) => number
  outputToConsole: OutputToConsoleFunc
  performanceEntryAwaitTimeouts: ResettableEventCounter
  performanceEntryManager: PerformanceEntryManager
  runtimeValues: RuntimeValues
}

const buildPopulation = (
  objectsSource: TestObject3[],
  endpointDoppler: string,
  endpointPulsarBase: string,
) => {
  const newTestObject = (sourceObj: TestObject3) => {
    return createPlatformTestObject(
      sourceObj,
      endpointDoppler,
      endpointPulsarBase,
    )
  }

  return objectsSource.map((value) => newTestObject(value))
}

export const initializeScheduledPopulation = (
  dependencies: Dependencies,
  sessionMetadata: SessionMetadata,
  pageSettings: PageSettings,
  objectsSource: TestObject3[],
  sessionConfig: SessionConfig,
) => {
  const population = buildPopulation(
    objectsSource,
    sessionConfig.beaconEndpointInfo.dopplerEndpoints
      .dopplerResourceTimingEndpoint,
    sessionConfig.beaconEndpointInfo.pulsarEndpointBase,
  )
  // Draw a weighted random sample for immediate measurement
  const sampleToMeasureImmediately = selectWeightedRandomSample(
    population,
    sessionConfig.siteOwnerSettings.maxInitialTestObjects,
    dependencies.newRandomNumber,
    // Use the target frequency as the "weighting factor". Since this could
    // be a decimal number less than 1, be sure to round up
    (config) => Math.ceil(config.targetFrequency),
    // Expect target frequencies less than 1 per minute
    60,
  )

  dependencies.measurementScheduler.initializePopulation(
    population,
    sessionMetadata,
    sampleToMeasureImmediately,
  )

  // Convert the result to a set of "executables" for OpenInsights to process
  return sampleToMeasureImmediately.map((config) =>
    makeExecutor(
      {
        applicationEvents: dependencies.applicationEvents,
        callClearTimeout: dependencies.callClearTimeout,
        callSendBeacon: dependencies.callSendBeacon,
        callSetTimeout: dependencies.callSetTimeout,
        callClearResourceTimings: dependencies.callClearResourceTimings,
        callFetch: dependencies.callFetch,
        maxTargetFrequency: dependencies.maxTargetFrequency,
        measurementScheduler: dependencies.measurementScheduler,
        newAbortController: dependencies.newAbortController,
        newDate: dependencies.newDate,
        newEpochTimestamp: dependencies.newEpochTimestamp,
        newMonotonicTimestamp: dependencies.newMonotonicTimestamp,
        newRandomNumber: dependencies.newRandomNumber,
        outputToConsole: dependencies.outputToConsole,
        performanceEntryAwaitTimeouts:
          dependencies.performanceEntryAwaitTimeouts,
        performanceEntryManager: dependencies.performanceEntryManager,
        runtimeValues: dependencies.runtimeValues,
      },
      config,
      sessionMetadata,
    ),
  )
}

export const updateScheduledPopulation = (
  measurementScheduler: Scheduler,
  sessionMetadata: SessionMetadata,
  objectsSource: TestObject3[],
  endpointDoppler: string,
  endpointPulsarBase: string,
) =>
  measurementScheduler.updatePopulation(
    buildPopulation(objectsSource, endpointDoppler, endpointPulsarBase),
    sessionMetadata,
  )
