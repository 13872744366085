import { TestObject3 } from "./config3"

export interface PlatformTestObject {
  addRandomSubdomainToken: boolean
  cdnResponseHeader: string | undefined
  dopplerPlatformID: string
  endpointDoppler: string
  endpointPulsarBase: string
  hdxEnabled: boolean
  hdxEnabledDefault: boolean
  includePulsar: boolean
  pulsarAppID: string | undefined
  pulsarJobID: string | undefined
  /**
   * The desired number of measurements per hour. May be capped by the system.
   */
  targetFrequency: number
  testObjectSize: number
  /**
   * Milliseconds before aborting fetch and reporting an availability issue.
   */
  testObjectTimeout: number
  testObjectURL: string
  popIDDetectionHeader: string | undefined
  popIDDetectionHeaderRegex: string | undefined
  popIDDetectionReqHeaders: Record<string, string> | undefined
}

export const createPlatformTestObject: (
  s: TestObject3,
  endpointDoppler: string,
  endpointPulsarBase: string,
) => PlatformTestObject = (s, d, p) => new PlatformTestObjectImpl(s, d, p)

class PlatformTestObjectImpl implements PlatformTestObject {
  private _addRandomSubdomainToken
  private _cdnResponseHeader
  private _dopplerPlatformID
  private _endpointDoppler
  private _endpointPulsarBase
  private _hdxEnabled
  private _hdxEnabledDefault
  private _includePulsar
  private _includeDoppler
  private _pulsarAppID
  private _pulsarJobID
  private _targetFrequency
  private _testObjectSize
  private _testObjectTimeout
  private _testObjectURL
  private _popIDDetectionHeader
  private _popIDDetectionHeaderRegex
  private _popIDDetectionReqHeaders
  constructor(
    source: TestObject3,
    endpointDoppler: string,
    endpointPulsarBase: string,
  ) {
    /* eslint-disable @typescript-eslint/dot-notation */
    const addRandomSubdomainToken = source["a"]
    const dopplerPlatformID = source["i"]
    const url = source["u"]
    const size = source["s"]
    const timeoutInSeconds = source["t"]
    const pulsarAppID = source["pa"]
    const pulsarJobID = source["pj"]
    const targetFrequency = source["f"]
    const sendToDoppler = source["d"]
    const sendToPulsar = source["p"] && !!pulsarAppID && !!pulsarJobID
    const usedForDecisioning = source["us"]
    const popIDDetectionHeader = source["po"] && source["po"]["h"]
    const popIDDetectionHeaderRegex = source["po"] && source["po"]["r"]
    const popIDDetectionReqHeaders = source["po"] && source["po"]["rh"]
    /* eslint-enable @typescript-eslint/dot-notation */
    this._addRandomSubdomainToken = addRandomSubdomainToken
    this._cdnResponseHeader = "x-cdn"
    this._dopplerPlatformID = dopplerPlatformID
    if (pulsarAppID) {
      this._pulsarAppID = pulsarAppID
    }
    if (pulsarJobID) {
      this._pulsarJobID = pulsarJobID
    }
    this._endpointDoppler = endpointDoppler
    this._endpointPulsarBase = endpointPulsarBase
    this._hdxEnabled = usedForDecisioning
    this._hdxEnabledDefault = true
    this._includePulsar = sendToPulsar
    this._includeDoppler = sendToDoppler
    this._targetFrequency = targetFrequency
    this._testObjectURL = url
    this._testObjectSize = size
    // this._testObjectTimeout is in milliseconds
    this._testObjectTimeout = 1000 * timeoutInSeconds
    this._popIDDetectionHeader = popIDDetectionHeader
    this._popIDDetectionHeaderRegex = popIDDetectionHeaderRegex
    this._popIDDetectionReqHeaders = popIDDetectionReqHeaders
  }
  get addRandomSubdomainToken() {
    return this._addRandomSubdomainToken
  }

  get cdnResponseHeader() {
    return this._cdnResponseHeader
  }

  get dopplerPlatformID() {
    return this._dopplerPlatformID
  }

  get endpointDoppler() {
    return this._endpointDoppler
  }

  get endpointPulsarBase() {
    return this._endpointPulsarBase
  }

  get hdxEnabled() {
    return this._hdxEnabled
  }

  get hdxEnabledDefault() {
    return this._hdxEnabledDefault
  }

  get includePulsar() {
    return this._includePulsar
  }

  get pulsarAppID() {
    return this._pulsarAppID
  }

  get pulsarJobID() {
    return this._pulsarJobID
  }

  get targetFrequency() {
    return this._targetFrequency
  }

  get testObjectSize() {
    switch (this._testObjectSize) {
      case 1555000:
        return 1555
      case 43:
        return 43
    }
    return Math.floor(this._testObjectSize / 1000)
  }

  get testObjectTimeout() {
    return this._testObjectTimeout
  }

  get testObjectURL() {
    return this._testObjectURL
  }

  get popIDDetectionHeader() {
    return this._popIDDetectionHeader
  }

  get popIDDetectionHeaderRegex() {
    return this._popIDDetectionHeaderRegex
  }

  get popIDDetectionReqHeaders() {
    return this._popIDDetectionReqHeaders
  }
}
