import { SessionMetadata } from "../@types"
import { PlatformTestObjectResultBundle } from "./makeExecutor"
import { PlatformTestObject } from "./platformTestObject"
import { RuntimeValues } from "./runtimeValues"

/**
 * Payload in the format expected by the beacon ingest service.
 * See components/rust/beacon-ingest.
 *
 * To reduce payload size, keys are kept intentionally terse by using the least
 * number of characters possible to convey useful information to a human reader.
 * Common keys are factored out further using nested objects. The payload will
 * be MessagePack-encoded, so things like commas, braces and quotes don't play
 * a role in payload size as they would with JSON.
 */
export type ResourceTimingBeaconPayload = {
  /**
   * Client information
   */
  c: {
    /**
     * Client version string
     */
    v: string
  }
  /**
   * Session information
   */
  s: {
    /**
     * Resolver IP
     */
    r?: string
    /**
     * Session start timestamp in milliseconds (Unix time)
     */
    s: number
    /**
     * Session ID, which is a UUIDv4.
     */
    i: string
    /**
     * Session config headers
     */
    h: {
      /**
       * Session config continent header
       */
      con: string
      /**
       * Session config country header
       */
      cou: string
      /**
       * Session config ASN header
       */
      a: string
    }
    /**
     * User agent information
     */
    u: {
      /**
       * Device category. This is always "web" for the JS client.
       */
      c: "web"
      /**
       * Device info from parsing the user agent string.
       */
      d: {
        /**
         * Device type
         */
        t?: string
        /**
         * Device vendor
         */
        v?: string
        /**
         * Device model
         */
        m?: string
      }
      /**
       * OS info from parsing the user agent string.
       */
      o: {
        /**
         * OS name
         */
        n?: string
        /**
         * OS version
         */
        v?: string
      }
      /**
       * Browser info from parsing the user agent string.
       */
      b: {
        /**
         * Browser name
         */
        n?: string
        /**
         * Browser version
         */
        v?: string
      }
    }
  }
  /**
   * Payload metadata. Used by the client to communicate ad hoc information about
   * itself or the measurement, e.g. if a particular feature flag was enabled.
   *
   * Specify the type `Record<string, never>` when unused to avoid lint warnings.
   */
  met: {
    /**
     * Whether data for the platform should be used for decisioning.
     * Server defaults to `true` if not provided.
     */
    hdx?: boolean
  }
  /**
   * Measurement information
   */
  mea: {
    /**
     * Doppler platform ID
     */
    i: string
    /**
     * Measurement start timestamp in milliseconds (Unix time)
     */
    s: number
    /**
     * The measurement URL including the cache busting query string generated at runtime.
     */
    u: string
    /**
     * The CDN name determined from checking the `x-cdn` header.
     */
    c?: string
    /**
     * The POP ID determined by checking a designated CDN POP response header.
     */
    p?: string
    /**
     * The full CDN POP header.
     */
    ph?: string
    /**
     * Measurement result
     */
    r: "success" | "timeout-abort" | "unknown-error"
    /**
     * Measurement HTTP status
     */
    st?: number
    /**
     * Test object information
     */
    o: {
      /**
       * Test object size in kilobytes
       */
      s: number
      /**
       * Max allowed interval in milliseconds before aborting the fetch and marking
       * the measurement as timed-out.
       */
      m: number
    }
    /**
     * Resource timing information. Undefined if the timeout expired and the download
     * aborted.
     */
    re?: {
      /**
       * Initiator type
       */
      i: string
      /**
       * Next hop protocol
       */
      n: string
      /**
       * Size data
       */
      si: {
        /**
         * Encoded body size
         */
        e?: number
        /**
         * Decoded body size
         */
        d?: number
        /**
         * Transfer size
         */
        t?: number
      }
      /**
       * `duration` (difference between `startTime` and `responseEnd`)
       */
      du: number
      /**
       * `startTime` timestamp, which is when the fetch started. Equivalent to `fetchStart`.
       */
      st: number
      /**
       * Container for `redirectStart` and `redirectEnd`.
       */
      red: {
        /**
         * `redirectStart`
         */
        s: number
        /**
         * `redirectEnd`
         */
        e: number
      }
      /**
       * `fetchStart`
       */
      f: number
      /**
       * Container for `domainLookupStart` and `domainLookupEnd`.
       */
      do: {
        s: number
        e: number
      }
      /**
       * Container for `connectStart`, `secureConnectionStart`, and `connectEnd`.
       */
      c: {
        /**
         * `connectStart`
         */
        s: number
        /**
         * `secureConnectionStart`
         */
        se: number
        /**
         * `connectEnd`
         */
        e: number
      }
      /**
       * `requestStart`
       */
      req: number
      /**
       * Container for `responseStart` and `responseEnd`.
       */
      res: {
        /**
         * `responseStart`
         */
        s: number
        /**
         * `responseEnd`
         */
        e: number
      }
    }
  }
}

/**
 * 0.4.0 - Add session config geo headers
 * 1.0.0 - Switch to MessagePack-encoded format
 */
export const Version: string = "1.0.0"

/**
 * Return a MessagePack-encoded string containing a measurement result.
 */
export default function (
  testObjectURL: string,
  testObjectConfig: PlatformTestObject,
  testResults: PlatformTestObjectResultBundle,
  sessionMetadata: SessionMetadata,
  runtimeValues: RuntimeValues,
) {
  const {
    lastReadSessConfHeaderContinent,
    lastReadSessConfHeaderCountry,
    lastReadSessConfHeaderAsn,
    resolverIP,
    sessionStartTimestamp,
    sessionUUID,
  } = runtimeValues
  if (!sessionStartTimestamp) {
    throw new Error("Session start timestamp not set")
  }
  if (!sessionUUID) {
    throw new Error("Session UUID not set")
  }
  if (!lastReadSessConfHeaderContinent) {
    throw new Error("Last read continent header missing")
  }
  if (!lastReadSessConfHeaderCountry) {
    throw new Error("Last read country header missing")
  }
  if (!lastReadSessConfHeaderAsn) {
    throw new Error("Last read ASN header missing")
  }
  if (!testResults.error && !testResults.entry) {
    throw new Error("Resource Timing entry missing")
  }
  // Hover over any of the keys to see a human-friendly description.
  const result: ResourceTimingBeaconPayload = {
    c: {
      v: sessionMetadata.clientVersion.join("."),
    },
    s: {
      s: sessionStartTimestamp.getTime(),
      i: sessionUUID,
      h: {
        con: lastReadSessConfHeaderContinent,
        cou: lastReadSessConfHeaderCountry,
        a: lastReadSessConfHeaderAsn,
      },
      u: {
        c: "web",
        d: {},
        o: {},
        b: {},
      },
    },
    met: {},
    mea: {
      i: testObjectConfig.dopplerPlatformID,
      s: testResults.testSetupResult.calledAt.getTime(),
      u: testObjectURL,
      r: testResults.error
        ? testResults.timeout
          ? "timeout-abort"
          : "unknown-error"
        : "success",
      o: {
        s: testObjectConfig.testObjectSize,
        m: testObjectConfig.testObjectTimeout,
      },
    },
  }
  testResults.cdnResponseHeaderValue &&
    (result.mea.c = testResults.cdnResponseHeaderValue)
  testResults.cdnPOPIDHeaderValue &&
    (result.mea.ph = testResults.cdnPOPIDHeaderValue)
  testResults.cdnPOPID && (result.mea.p = testResults.cdnPOPID)
  typeof testResults.status == "number" && (result.mea.st = testResults.status)
  const browserMetadata = sessionMetadata.browserMeta
  const deviceMetadata = browserMetadata.device
  deviceMetadata.type && (result.s.u.d.t = deviceMetadata.type)
  deviceMetadata.vendor && (result.s.u.d.v = deviceMetadata.vendor)
  deviceMetadata.model && (result.s.u.d.m = deviceMetadata.model)
  const osMetadata = browserMetadata.os
  osMetadata.name && (result.s.u.o.n = osMetadata.name)
  osMetadata.version && (result.s.u.o.v = osMetadata.version)
  const browserBrowserMetadata = browserMetadata.browser
  browserBrowserMetadata.name && (result.s.u.b.n = browserBrowserMetadata.name)
  browserBrowserMetadata.version &&
    (result.s.u.b.v = browserBrowserMetadata.version)
  // Only override hdx flag if pertinent (server default is true).
  if (testObjectConfig.hdxEnabled !== testObjectConfig.hdxEnabledDefault) {
    result.met.hdx = testObjectConfig.hdxEnabled
  }
  if (testResults.entry) {
    const entry = testResults.entry as PerformanceResourceTiming
    const floor = (value: number) => Math.floor(value)
    result.mea.re = {
      c: {
        e: floor(entry.connectEnd),
        s: floor(entry.connectStart),
        se: floor(entry.secureConnectionStart),
      },
      do: {
        e: floor(entry.domainLookupEnd),
        s: floor(entry.domainLookupStart),
      },
      du: floor(entry.duration),
      f: floor(entry.fetchStart),
      i: entry.initiatorType,
      n: entry.nextHopProtocol,
      red: {
        e: floor(entry.redirectEnd),
        s: floor(entry.redirectStart),
      },
      req: floor(entry.requestStart),
      res: {
        e: floor(entry.responseEnd),
        s: floor(entry.responseStart),
      },
      si: {},
      st: floor(entry.startTime),
    }
    if (typeof entry.decodedBodySize === "number") {
      result.mea.re.si.d = entry.decodedBodySize
    }
    if (typeof entry.encodedBodySize === "number") {
      result.mea.re.si.e = entry.encodedBodySize
    }
    if (typeof entry.transferSize === "number") {
      result.mea.re.si.t = entry.transferSize
    }
  }
  if (resolverIP) {
    result.s.r = resolverIP
  }
  return result
}
