import { DepID_DNSClientInfoSamplePercentageCheck } from "../@types"
import { Dependencies } from "./client"
import { Config3Source } from "./config3"

export type DNSClientInfoSource = {
  client_ip: string
  resolver_ip: string
}

export default function (
  dependencies: Dependencies,
  source: Config3Source,
): Promise<Config3Source> {
  const sessionUUID = dependencies.runtimeValues.sessionUUID
  if (!sessionUUID) {
    return Promise.reject("Session UUID not set")
  }

  /* eslint-disable @typescript-eslint/dot-notation */
  const baseHostname = source["s"]["dcibh"]
  const samplePercentage = source["s"]["dcisp"]
  /* eslint-enable @typescript-eslint/dot-notation */

  const url = `https://${sessionUUID}.${baseHostname}/clientinfo`

  // samplePercentage is in the range [0,1].
  return dependencies.newRandomNumber(
    DepID_DNSClientInfoSamplePercentageCheck,
  ) < samplePercentage
    ? dependencies
        .callFetch(url, {
          // Add a timeout to the DNS Client Info request. This should allow
          // measurements to continue, only without the resolver IP.
          signal: dependencies.callAbortSignalTimeout(
            DepID_DNSClientInfoSamplePercentageCheck,
            5000,
          ),
        })
        .then((response) => response.json() as Promise<DNSClientInfoSource>)
        .then((responseData) => {
          /* eslint-disable @typescript-eslint/dot-notation */
          const resolverIP = responseData["resolver_ip"]
          /* eslint-enable @typescript-eslint/dot-notation */
          dependencies.runtimeValues.resolverIP = resolverIP
          return source
        })
        .catch((_e) => {
          // Consider writing to the application event log.
          return source
        })
    : Promise.resolve(source)
}
