import version from "../version"
import { PageSettings } from "./pageSettings"

/**
 * The number of milliseconds to wait before making the initial session
 * configuration request.
 */
export function getSessionConfigDelay(
  defaultValueInMilliseconds: number,
  pageSettings: PageSettings,
): number {
  return typeof pageSettings.sessionConfigDelay === "number"
    ? pageSettings.sessionConfigDelay
    : defaultValueInMilliseconds
}

export function getSessionConfigURL(pageSettings: PageSettings) {
  const sessionConfigOrigin = pageSettings.sessionConfigOrigin
    ? pageSettings.sessionConfigOrigin
    : "https://doppler-config.cbsivideo.com"
  const qs: string[] = [`clientversion=${version}`]
  if (pageSettings.rumConfigCountryOverride) {
    qs.push(`c=${pageSettings.rumConfigCountryOverride}`)
  }
  if (pageSettings.rumConfigStateOverride) {
    qs.push(`s=${pageSettings.rumConfigStateOverride}`)
  }
  if (pageSettings.rumConfigASNOverride) {
    qs.push(`a=${pageSettings.rumConfigASNOverride}`)
  }
  if (pageSettings.blockedReferrer) {
    qs.push(`blocked-referrer=${pageSettings.blockedReferrer}`)
  }
  return `${sessionConfigOrigin}/v3/config?${qs.join("&")}`
}
