import {
  ConsoleOutputLevel,
  DepID_IntervalCalculator,
  HasValue,
  OutputToConsoleFunc,
} from "../@types"

export type Dependencies = {
  maxHourlyRate: HasValue<number>
  newRandomNumber: (depID: number) => number
  outputToConsole: OutputToConsoleFunc
}

export type Instances = number
export type Numerator = number
export type DenominatorStart = number
export type DenominatorEnd = number
export type DistributionRecord = [
  Instances,
  Numerator,
  DenominatorStart,
  DenominatorEnd,
]
export type Distribution = DistributionRecord[]

export interface Calculator {
  /**
   * Calculate a number of milliseconds based on a target rate per hour,
   * applying a fuzziness modifier if the instance was given a specification.
   * @param hourlyRate The base hourly rate
   */
  calculateInterval(hourlyRate: number): number
}

export const createIntervalCalculator: (
  dependencies: Dependencies,
  distribution: Distribution,
) => Calculator = (d, di) => new CalculatorImpl(d, di)

class CalculatorImpl implements Calculator {
  private _fuzzinessModifiers: Float32Array

  /**
   * @param distribution A specification for the desired distribution of
   * fuzziness modifiers. Passing an empty array results in no fuzziness
   * being applied when the caller invokes `calculate` on the instance.
   */
  constructor(
    private _dependencies: Dependencies,
    distribution: Distribution,
  ) {
    const iterator = {
      *[Symbol.iterator]() {
        for (let i = 0; i < distribution.length; i++) {
          const [instances, numerator, denominatorStart, denominatorEnd] =
            distribution[i]
          for (
            let denominator = denominatorStart;
            denominator < denominatorEnd;
            denominator++
          ) {
            const value = numerator / denominator
            for (let i = 0; i < instances; i++) {
              yield value
            }
          }
        }
      },
    }
    this._fuzzinessModifiers = new Float32Array(iterator)
  }

  /**
   * Calculate a number of milliseconds based on a target rate per hour,
   * applying a fuzziness modifier if the instance was given a specification.
   * @param hourlyRate The base hourly rate. May be capped if maxHourlyRate
   * has been set.
   */
  calculateInterval(hourlyRate: number) {
    // Cap hourly rate if max has been set.
    const actualHourlyRate =
      this._dependencies.maxHourlyRate.value > 0
        ? Math.min(hourlyRate, this._dependencies.maxHourlyRate.value)
        : hourlyRate
    this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () => {
      const capDetail =
        hourlyRate == actualHourlyRate
          ? `hourly rate not capped`
          : `hourly rate capped at ${actualHourlyRate} from ${hourlyRate}`
      return `Calculating interval (${capDetail})`
    })
    const intervalInHours = 1 / actualHourlyRate
    const MILLISECONDS_PER_HOUR = 3.6e6
    const intervalInMilliseconds = intervalInHours * MILLISECONDS_PER_HOUR
    const modifier =
      0 < this._fuzzinessModifiers.length
        ? this._fuzzinessModifiers[
            Math.floor(
              this._dependencies.newRandomNumber(DepID_IntervalCalculator) *
                this._fuzzinessModifiers.length,
            )
          ]
        : 1
    return Math.floor(intervalInMilliseconds * modifier)
  }
}
