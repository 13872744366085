import { defaultSessionProcessFunc } from "./defaultSessionProcessFunc"

/**
 * An interface representing an object with an execute method, generally a
 * {@link Test}.
 */
export interface Executable {
  /**
   * Execute a test and return a Promise that resolves to a provider-defined
   * test result object.
   */
  execute(): Promise<unknown>
}

export type ProduceExecutablesFunc = (
  sessionConfiguration: unknown,
) => Executable[]

export type FetchSessionConfigurationFunc = () => Promise<unknown>

export type SessionStartupFuncs = {
  fetchSessionConfiguration: FetchSessionConfigurationFunc
  produceExecutables: ProduceExecutablesFunc
}

/**
 * Encapsulates the result of one provider's RUM session.
 */
export interface SessionResult {
  /**
   * An array containing the test result bundles for each individual test
   * performed.
   */
  testResults: unknown[]
}

/**
 * Called internally if a non-zero `sessionConfigDelayInMilliseconds` is passed
 * by the client setting has been specified.
 */
export function startLater(
  sessionConfigDelayInMilliseconds: number,
  sessionStartupFuncs: SessionStartupFuncs[],
): Promise<SessionResult> {
  return new Promise((resolve) => {
    setTimeout(() => {
      start(sessionStartupFuncs).then((result) => resolve(result))
    }, sessionConfigDelayInMilliseconds)
  })
}

export function start(
  sessionStartupFuncs: SessionStartupFuncs[],
): Promise<SessionResult> {
  return Promise.allSettled(
    sessionStartupFuncs.map((funcs) => funcs.fetchSessionConfiguration()),
  ).then((settled) => {
    const executables: Executable[] = []
    settled.forEach((result, idx) => {
      if (result.status === "fulfilled" && "value" in result) {
        executables.push(
          ...sessionStartupFuncs[idx].produceExecutables(result.value),
        )
      }
    })
    const process = defaultSessionProcessFunc
    return process(executables)
  })
}
