export interface RuntimeValues {
  applicationEventsBeaconIntervalID: number | undefined
  applicationEventsBeaconIntervalInMilliseconds: number | undefined
  disallowedNetworks: number[] | undefined
  lastReadBeaconContinent: string | undefined
  lastReadBeaconCountry: string | undefined
  lastReadBeaconState: string | undefined
  lastReadBeaconAsn: number | undefined
  lastReadSessConfHeaderContinent: string | undefined
  lastReadSessConfHeaderCountry: string | undefined
  lastReadSessConfHeaderState: string | undefined
  lastReadSessConfHeaderAsn: string | undefined
  measurementsEnabled: boolean
  resolverIP: string | undefined
  schedulerRunIntervalID: number | undefined
  schedulerRunIntervalInMilliseconds: number | undefined
  sessionConfigLastUpdatedAt: Date | undefined
  sessionConfigRefreshIntervalID: number | undefined
  sessionRefreshDelayInMilliseconds: number | undefined
  sessionStartTimestamp: Date | undefined
  sessionUUID: string | undefined
  updateMeasurementsEnabledForNetwork(asn: number): void
}

export const createRuntimeValues: () => RuntimeValues = () =>
  new RuntimeValuesImpl()

class RuntimeValuesImpl implements RuntimeValues {
  // Prefer assignment in constructor to avoid compiler using
  // Object.defineProperty, which breaks property munging
  private _applicationEventsBeaconIntervalID
  private _applicationEventsBeaconIntervalInMilliseconds
  private _disallowedNetworks
  private _lastReadBeaconContinent
  private _lastReadBeaconCountry
  private _lastReadBeaconState
  private _lastReadBeaconAsn
  private _lastReadSessConfHeaderContinent
  private _lastReadSessConfHeaderCountry
  private _lastReadSessConfHeaderState
  private _lastReadSessConfHeaderAsn
  private _measurementsEnabled
  private _resolverIP
  private _schedulerRunIntervalID
  private _schedulerRunIntervalInMilliseconds
  private _sessionConfigLastUpdatedAt
  private _sessionConfigRefreshIntervalID
  private _sessionRefreshDelayInMilliseconds
  private _sessionStartTimestamp
  private _sessionUUID

  constructor() {
    this._applicationEventsBeaconIntervalID = undefined as number | undefined
    this._applicationEventsBeaconIntervalInMilliseconds = undefined as
      | number
      | undefined
    this._disallowedNetworks = [] as number[]
    this._lastReadBeaconContinent = undefined as string | undefined
    this._lastReadBeaconCountry = undefined as string | undefined
    this._lastReadBeaconState = undefined as string | undefined
    this._lastReadBeaconAsn = undefined as number | undefined
    this._lastReadSessConfHeaderContinent = undefined as string | undefined
    this._lastReadSessConfHeaderCountry = undefined as string | undefined
    this._lastReadSessConfHeaderState = undefined as string | undefined
    this._lastReadSessConfHeaderAsn = undefined as string | undefined
    this._measurementsEnabled = false as boolean
    this._resolverIP = undefined as string | undefined
    this._schedulerRunIntervalID = undefined as number | undefined
    this._schedulerRunIntervalInMilliseconds = undefined as number | undefined
    this._sessionConfigLastUpdatedAt = undefined as Date | undefined
    this._sessionConfigRefreshIntervalID = undefined as number | undefined
    this._sessionRefreshDelayInMilliseconds = undefined as number | undefined
    this._sessionStartTimestamp = undefined as Date | undefined
    this._sessionUUID = undefined as string | undefined
  }

  get disallowedNetworks() {
    return this._disallowedNetworks
  }

  set disallowedNetworks(value: number[]) {
    this._disallowedNetworks = value
  }

  get applicationEventsBeaconIntervalID() {
    return this._applicationEventsBeaconIntervalID
  }

  set applicationEventsBeaconIntervalID(value: number | undefined) {
    this._applicationEventsBeaconIntervalID = value
  }

  get applicationEventsBeaconIntervalInMilliseconds() {
    return this._applicationEventsBeaconIntervalInMilliseconds
  }

  set applicationEventsBeaconIntervalInMilliseconds(value: number | undefined) {
    this._applicationEventsBeaconIntervalInMilliseconds = value
  }

  get lastReadBeaconContinent() {
    return this._lastReadBeaconContinent
  }

  set lastReadBeaconContinent(value: string | undefined) {
    this._lastReadBeaconContinent = value
  }

  get lastReadBeaconCountry() {
    return this._lastReadBeaconCountry
  }

  set lastReadBeaconCountry(value: string | undefined) {
    this._lastReadBeaconCountry = value
  }

  get lastReadBeaconState() {
    return this._lastReadBeaconState
  }

  set lastReadBeaconState(value: string | undefined) {
    this._lastReadBeaconState = value
  }

  get lastReadBeaconAsn() {
    return this._lastReadBeaconAsn
  }

  set lastReadBeaconAsn(value: number | undefined) {
    this._lastReadBeaconAsn = value
  }

  get lastReadSessConfHeaderContinent() {
    return this._lastReadSessConfHeaderContinent
  }

  set lastReadSessConfHeaderContinent(value: string | undefined) {
    this._lastReadSessConfHeaderContinent = value
  }

  get lastReadSessConfHeaderCountry() {
    return this._lastReadSessConfHeaderCountry
  }

  set lastReadSessConfHeaderCountry(value: string | undefined) {
    this._lastReadSessConfHeaderCountry = value
  }

  get lastReadSessConfHeaderState() {
    return this._lastReadSessConfHeaderState
  }

  set lastReadSessConfHeaderState(value: string | undefined) {
    this._lastReadSessConfHeaderState = value
  }

  get lastReadSessConfHeaderAsn() {
    return this._lastReadSessConfHeaderAsn
  }

  set lastReadSessConfHeaderAsn(value: string | undefined) {
    this._lastReadSessConfHeaderAsn = value
  }

  get measurementsEnabled() {
    return this._measurementsEnabled
  }

  set measurementsEnabled(value: boolean) {
    this._measurementsEnabled = value
  }

  get resolverIP() {
    return this._resolverIP
  }

  set resolverIP(value: string | undefined) {
    this._resolverIP = value
  }

  get schedulerRunIntervalID() {
    return this._schedulerRunIntervalID
  }

  set schedulerRunIntervalID(value: number | undefined) {
    this._schedulerRunIntervalID = value
  }

  get schedulerRunIntervalInMilliseconds() {
    return this._schedulerRunIntervalInMilliseconds
  }

  set schedulerRunIntervalInMilliseconds(value: number | undefined) {
    this._schedulerRunIntervalInMilliseconds = value
  }

  get sessionConfigLastUpdatedAt() {
    return this._sessionConfigLastUpdatedAt
  }

  set sessionConfigLastUpdatedAt(value: Date | undefined) {
    this._sessionConfigLastUpdatedAt = value
  }

  get sessionConfigRefreshIntervalID() {
    return this._sessionConfigRefreshIntervalID
  }

  set sessionConfigRefreshIntervalID(value: number | undefined) {
    this._sessionConfigRefreshIntervalID = value
  }

  get sessionRefreshDelayInMilliseconds() {
    return this._sessionRefreshDelayInMilliseconds
  }

  set sessionRefreshDelayInMilliseconds(value: number | undefined) {
    this._sessionRefreshDelayInMilliseconds = value
  }

  get sessionStartTimestamp() {
    return this._sessionStartTimestamp
  }

  set sessionStartTimestamp(value: Date | undefined) {
    this._sessionStartTimestamp = value
  }

  get sessionUUID() {
    return this._sessionUUID
  }

  set sessionUUID(value: string | undefined) {
    this._sessionUUID = value
  }

  updateMeasurementsEnabledForNetwork(asn: number): void {
    this._measurementsEnabled = !this._disallowedNetworks.includes(asn)
  }
}
