import {
  ConsoleOutputLevel,
  DepID_OpenInsightsFetch,
  DepID_PlatformTestObjectBeforeBeacon,
  DepID_PlatformTestObjectBeforeTest,
  DepID_PlatformTestObjectOnError,
  DepID_PlatformTestObjectSendDopplerBeacon,
  DepID_PlatformTestObjectSendPulsarBeacon,
  HasValue,
  OutputToConsoleFunc,
  SessionMetadata,
} from "../@types"
import { ApplicationEvents } from "./applicationEvents"
import makeResourceTimingReportPayload, {
  Version as beaconPayloadVersion,
} from "./makeResourceTimingReportPayload"
import makeResourceURL from "./makeResourceURL"
import { Scheduler } from "./measurementScheduler"
import { FetchTestResult } from "./openinsights/fetch"
import {
  PerformanceEntryAwaitTimeout,
  PerformanceEntryManager,
  RegisteredCallbackNotConsumedError,
} from "./openinsights/resourceTiming"
import { PlatformTestObject } from "./platformTestObject"
import { ResettableEventCounter } from "./resettableEventCounter"
import { RuntimeValues } from "./runtimeValues"
import { fetch as OpenInsightsFetch } from "./openinsights/fetch"
import { Executable } from "./openinsights/start"
import { encode as messagePackEncode } from "../vendor/msgpack/encode"
import randomString from "./randomString"

export interface Dependencies {
  applicationEvents: ApplicationEvents
  callClearTimeout: (depID: number, timeoutID: number) => void
  callFetch: (url: string, options?: RequestInit) => Promise<Response>
  callSendBeacon: (depID: number, url: string) => boolean
  callSetTimeout: (depID: number, fn: () => void, timeout: number) => number
  callClearResourceTimings: () => void
  maxTargetFrequency: HasValue<number>
  measurementScheduler: Scheduler
  newAbortController: () => AbortController
  newDate: (depID: number) => Date
  newEpochTimestamp: (depID: number) => number
  newMonotonicTimestamp: (depID: number) => number
  newRandomNumber: (depID: number) => number
  outputToConsole: OutputToConsoleFunc
  performanceEntryAwaitTimeouts: ResettableEventCounter
  performanceEntryManager: PerformanceEntryManager
  runtimeValues: RuntimeValues
}

export interface TestSetupResult {
  /**
   * Passed as an ISO-formatted date in the beacon data as a *rough* and
   * generally untrustworthy estimate of when the measurement was performed.
   */
  calledAt: Date
}

export type PlatformTestObjectResultBundle = FetchTestResult & {
  cdnResponseHeaderValue?: string
  cdnPOPID?: string
  cdnPOPIDHeaderValue?: string
  testSetupResult: TestSetupResult
}

const newOnErrorMessage = (dopplerPlatformID: string, e: unknown, at: Date) =>
  `onError ${dopplerPlatformID} (${at.toISOString()}): ${e}`

const maxTargetFrequencyChangedMessage = (from: number, to: number) =>
  `Max target frequency changed by beacon response from ${from} to ${to}`

type BeaconRequestHeaders = {
  "doppler-payload-version": string
  "content-type": string
}

export type BeaconResponsePayloadGeo = {
  /**
   * continent code
   */
  con: string
  /**
   * country
   */
  cou: string
  /**
   * subdiv (state)
   */
  s: string
  /**
   * ASN
   */
  a: number
}

/**
 * Mirrors structure in components/rust/beacon-ingest/src/model/beacon_response_payload.rs
 */
export type BeaconResponsePayload = {
  /**
   * client geo
   */
  g: BeaconResponsePayloadGeo
  /**
   * max target frequency
   */
  m?: number
}

export default function (
  deps: Dependencies,
  config: PlatformTestObject,
  sessionMetadata: SessionMetadata,
) {
  let baseURL = config.testObjectURL
  if (config.addRandomSubdomainToken) {
    const token = randomString(20, deps.newRandomNumber).toLowerCase()
    baseURL = decodeURI(baseURL)
    baseURL = baseURL.replace("${token}", token)
  }
  const resourceURL = makeResourceURL(baseURL, deps.newRandomNumber)
  /**
   * Milliseconds elapsed since baseline timestamp
   */
  let testObjectDownloadMonotonicStartTime: number
  const result: Executable = {
    execute: () =>
      OpenInsightsFetch<PlatformTestObjectResultBundle>(
        {
          clearTimeout: (timeoutID: number) =>
            deps.callClearTimeout(DepID_OpenInsightsFetch, timeoutID),
          fetch: deps.callFetch,
          newAbortController: deps.newAbortController,
          newDate: () => deps.newDate(DepID_OpenInsightsFetch),
          performanceEntryManager: deps.performanceEntryManager,
          setTimeout: (fn: () => void, timeout: number) =>
            deps.callSetTimeout(DepID_OpenInsightsFetch, fn, timeout),
        },
        resourceURL,
        config.testObjectTimeout,
        ((c) => {
          deps.outputToConsole(
            ConsoleOutputLevel.debug,
            () => `POP ID detection header: ${c.popIDDetectionHeader}`,
          )
          deps.outputToConsole(
            ConsoleOutputLevel.debug,
            () =>
              `POP ID detection request headers: ${JSON.stringify(
                c.popIDDetectionReqHeaders,
              )}`,
          )
          if (c.popIDDetectionHeader && c.popIDDetectionHeader.length > 0) {
            if (
              c.popIDDetectionReqHeaders &&
              Object.keys(c.popIDDetectionReqHeaders).length > 0
            ) {
              return c.popIDDetectionReqHeaders
            }
          }
          return null
        })(config),
        // beforeTest
        () => {
          if (deps.runtimeValues.measurementsEnabled) {
            deps.applicationEvents.recordBeforeTestObjectDownloadEvent(
              deps.newEpochTimestamp(DepID_PlatformTestObjectBeforeTest),
              deps.runtimeValues,
              config,
            )
            testObjectDownloadMonotonicStartTime = deps.newMonotonicTimestamp(
              DepID_PlatformTestObjectBeforeTest,
            )
            return Promise.resolve({
              testSetupResult: {
                calledAt: deps.newDate(DepID_PlatformTestObjectBeforeTest),
              },
              timeout: false,
            })
          }
          return Promise.reject(
            new Error(
              "beforeTest: Measurement blocked (measurements disabled)",
            ),
          )
        },
        // beforeBeacon provides an opportunity to do things like read response
        // headers and further tag the test result bundle.
        (testResult, response) => {
          deps.applicationEvents.recordAfterTestObjectDownloadEvent(
            deps.newDate(DepID_PlatformTestObjectBeforeBeacon).getTime(),
            deps.runtimeValues,
            config,
            Math.floor(
              deps.newMonotonicTimestamp(DepID_PlatformTestObjectBeforeBeacon) -
                testObjectDownloadMonotonicStartTime,
            ),
          )
          if (config.cdnResponseHeader) {
            const cdnName = response.headers.get(config.cdnResponseHeader)
            if (cdnName) {
              testResult.cdnResponseHeaderValue = cdnName
            }
          }
          if (config.popIDDetectionHeader) {
            const cdnPOPHeader = response.headers.get(
              config.popIDDetectionHeader,
            )
            if (cdnPOPHeader) {
              testResult.cdnPOPIDHeaderValue = cdnPOPHeader
              if (config.popIDDetectionHeaderRegex) {
                const regex = new RegExp(config.popIDDetectionHeaderRegex)
                const result = regex.exec(cdnPOPHeader)
                /* eslint-disable @typescript-eslint/dot-notation */
                const popID = result && result.groups && result.groups["pop"]
                /* eslint-enable @typescript-eslint/dot-notation */
                if (typeof popID == "string" && popID.length) {
                  testResult.cdnPOPID = popID
                }
              } else {
                testResult.cdnPOPID = cdnPOPHeader
              }
            }
          }
          return Promise.resolve()
        },
        // sendBeacon
        async (testResult) => {
          try {
            await sendDopplerBeacon(testResult)
            sendLegacyPulsarBeacon(testResult)
          } catch (_e) {
            // Catching exceptions here helps to prevent beacon errors from
            // appearing in the browser console, but it also reduces visibility
            // for troubleshooting purposes. Consider sending these to the
            // application log.
          }
        },
        // afterTest
        (testResult) => {
          deps.measurementScheduler.scheduleNextMeasurement(config)
          deps.measurementScheduler.outputDiagnosticMessage()
          return Promise.resolve(testResult)
        },
        // onError
        (e) => {
          deps.outputToConsole(ConsoleOutputLevel.debug, () =>
            newOnErrorMessage(
              config.dopplerPlatformID,
              e,
              deps.newDate(DepID_PlatformTestObjectOnError),
            ),
          )
          if (e instanceof RegisteredCallbackNotConsumedError) {
            // This means another measurement is still underway
            deps.measurementScheduler.scheduleNextMeasurementSoon(config)
          } else if (e instanceof PerformanceEntryAwaitTimeout) {
            // Generally an issue with the Resource Timing buffer,
            // mainly in Firefox.
            // For browsers that shut down tracking further performance
            // events until the buffer is cleared, now is an acceptable
            // time to do that.
            // Arbitrary number of errors to allow before clearing the
            // buffer again
            deps.performanceEntryAwaitTimeouts.add(1)
            if (deps.performanceEntryAwaitTimeouts.countOfEvents > 5) {
              deps.callClearResourceTimings()
              deps.performanceEntryAwaitTimeouts.reset()
            }
            // Reschedule normally
            deps.measurementScheduler.scheduleNextMeasurementSoon(config)
          }
        },
      ),
  }
  return result

  function sendDopplerBeacon(testResult: PlatformTestObjectResultBundle) {
    const beaconRequestMonotonicStartTime = deps.newMonotonicTimestamp(
      DepID_PlatformTestObjectSendDopplerBeacon,
    )
    deps.applicationEvents.recordBeforeMeasurementBeaconEvent(
      deps.newEpochTimestamp(DepID_PlatformTestObjectSendDopplerBeacon),
      deps.runtimeValues,
    )
    const headers: BeaconRequestHeaders = {
      "doppler-payload-version": beaconPayloadVersion,
      "content-type": "application/json",
    }
    const payload = makeResourceTimingReportPayload(
      resourceURL,
      config,
      testResult,
      sessionMetadata,
      deps.runtimeValues,
    )
    const options = {
      method: "POST",
      // Explicitly allow request to outlive page similar to sendBeacon
      keepalive: true,
      headers: new Headers(headers),
      body: messagePackEncode(payload),
    }
    return deps
      .callFetch(config.endpointDoppler, options)
      .then((resp) => resp.json() as Promise<BeaconResponsePayload>)
      .then((payload) => {
        // Deem the beacon successful
        deps.applicationEvents.recordAfterMeasurementBeaconEvent(
          deps.newEpochTimestamp(DepID_PlatformTestObjectSendDopplerBeacon),
          deps.runtimeValues,
          Math.floor(
            deps.newMonotonicTimestamp(
              DepID_PlatformTestObjectSendDopplerBeacon,
            ) - beaconRequestMonotonicStartTime,
          ),
        )
        const continent = payload.g.con
        const country = payload.g.cou
        const subdiv = payload.g.s
        const asn = payload.g.a
        if (continent) {
          deps.runtimeValues.lastReadBeaconContinent = continent
        }
        if (country) {
          deps.runtimeValues.lastReadBeaconCountry = country
        }
        if (subdiv) {
          deps.runtimeValues.lastReadBeaconState = subdiv
        }
        if (asn) {
          deps.runtimeValues.lastReadBeaconAsn = asn
          deps.runtimeValues.updateMeasurementsEnabledForNetwork(asn)
        }
        // Reschedule measurements if there's been a change to the max target frequency.
        if (
          typeof payload.m == "number" &&
          payload.m > 0 &&
          deps.maxTargetFrequency.value != payload.m
        ) {
          const makeMessageFunc = (from: number, to: number) => () =>
            maxTargetFrequencyChangedMessage(from, to)
          deps.outputToConsole(
            ConsoleOutputLevel.debug,
            makeMessageFunc(deps.maxTargetFrequency.value, payload.m),
          )
          deps.maxTargetFrequency.value = payload.m
          deps.measurementScheduler.reschedule()
        } else if (
          (typeof payload.m != "number" || payload.m <= 0) &&
          deps.maxTargetFrequency.value != 0
        ) {
          deps.outputToConsole(ConsoleOutputLevel.debug, () =>
            maxTargetFrequencyChangedMessage(deps.maxTargetFrequency.value, 0),
          )
          deps.maxTargetFrequency.value = 0
          deps.measurementScheduler.reschedule()
        }
      })
      .catch((e) => {
        if (e instanceof Error) {
          deps.applicationEvents.recordMeasurementBeaconErrorEvent(
            deps.newEpochTimestamp(DepID_PlatformTestObjectSendDopplerBeacon),
            deps.runtimeValues,
            e.message,
          )
        }
      })
  }

  function sendLegacyPulsarBeacon(testResult: PlatformTestObjectResultBundle) {
    if (config.includePulsar) {
      if (testResult.error || testResult.status !== 200) {
        const intervalInMilliseconds = Math.floor(
          deps.newMonotonicTimestamp(DepID_PlatformTestObjectSendPulsarBeacon) -
            testObjectDownloadMonotonicStartTime,
        )
        // Not sure if NS1 would rather have 650 here or the real
        // non-200 status
        deps.callSendBeacon(
          DepID_PlatformTestObjectSendPulsarBeacon,
          makeBeaconURL(650, intervalInMilliseconds),
        )
      } else {
        const entry = testResult.entry as PerformanceResourceTiming
        deps.callSendBeacon(
          DepID_PlatformTestObjectSendPulsarBeacon,
          makeBeaconURL(
            testResult.status,
            // TTLB
            Math.floor(entry.responseEnd - entry.requestStart),
          ),
        )
      }
    }

    function makeBeaconURL(httpStatus: number, value: number) {
      const randomString = Math.floor(
        deps.newRandomNumber(DepID_PlatformTestObjectSendPulsarBeacon) *
          (4294967295 + 1),
      )
      // eslint-disable-next-line max-len
      return `${config.endpointPulsarBase}&a=${config.pulsarAppID}&x=${randomString}&r=${config.pulsarJobID}:x|${httpStatus}|${value}`
    }
  }
}
