export interface ResettableEventCounter {
  add(count: number): void
  reset(): void
  countOfEvents: number
}

export const createResettableEventCounter: () => ResettableEventCounter = () =>
  new ResettableEventCounterImpl()

class ResettableEventCounterImpl implements ResettableEventCounter {
  // Prefer assignment in constructor to avoid compiler using
  // Object.defineProperty, which breaks property munging
  private _countOfEvents
  constructor() {
    this._countOfEvents = 0
  }
  add(count: number) {
    this._countOfEvents += count
  }
  reset() {
    this._countOfEvents = 0
  }
  get countOfEvents() {
    return this._countOfEvents
  }
}
