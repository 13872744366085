import { DepID_RandomStringCharacterSelection } from "../@types"

export default (
  length: number,
  newRandomNumber: (depID: number) => number,
): string => {
  const DICTIONARY =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const DICTIONARY_LENGTH = DICTIONARY.length
  const randomLetter = (): string =>
    DICTIONARY.charAt(
      Math.floor(
        newRandomNumber(DepID_RandomStringCharacterSelection) *
          DICTIONARY_LENGTH,
      ),
    )
  return Array(length)
    .fill(null)
    .reduce((acc): string => acc + randomLetter(), "")
}
