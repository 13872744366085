import { DepID_SelectWeightedRandomSample } from "../@types"

export default function fn<T>(
  population: T[],
  count: number,
  getRandom: () => number,
) {
  const copyOfInput = [...population]
  // Fisher-Yates shuffle
  for (let i = copyOfInput.length - 1; i > 0; i--) {
    const j = Math.floor(getRandom() * (i + 1))
    const temp = copyOfInput[i]
    copyOfInput[i] = copyOfInput[j]
    copyOfInput[j] = temp
  }
  return copyOfInput.slice(0, count)
}

/**
 *
 * @param population
 * @param count
 * @param newRandomNumber
 * @param getWeightingFactorFn A function returning a positive whole number
 * @param maxExpectedWeightingFactor
 * @returns
 */
export function selectWeightedRandomSample<T>(
  population: T[],
  count: number,
  newRandomNumber: (depID: number) => number,
  getWeightingFactorFn: (obj: T) => number,
  maxExpectedWeightingFactor: number,
) {
  const copyOfInput = population.flatMap((obj) => {
    // Cap granularity to avoid using too much memory
    const weightingFactor = Math.min(
      getWeightingFactorFn(obj),
      maxExpectedWeightingFactor,
    )
    return Array<T>(weightingFactor).fill(obj)
  })
  const result: T[] = []
  let i = count
  while (i-- && copyOfInput.length) {
    // Select a random item from the weighted array
    const obj =
      copyOfInput[
        Math.floor(
          newRandomNumber(DepID_SelectWeightedRandomSample) *
            copyOfInput.length,
        )
      ]
    result.push(obj)
    // Remove the item so it can't be selected again
    copyOfInput.reduceRight((prev, curr, idx) => {
      if (curr === obj) {
        prev.splice(idx, 1)
      }
      return prev
    }, copyOfInput)
  }
  return result
}
