import {
  ConsoleOutputLevel,
  DepID_MeasurementSchedulerRun,
  DepID_SchedulerInitializePopulation,
  DepID_SchedulerOutputDiagnosticsMessage,
  DepID_SchedulerReschedule,
  DepID_SchedulerScheduleNextMeasurement,
  DepID_SchedulerScheduleNextMeasurementSoon,
  DepID_SchedulerUpdatePopulation,
  HasValue,
  OutputToConsoleFunc,
  SessionMetadata,
} from "../@types"
import { ApplicationEvents } from "./applicationEvents"
import { Calculator } from "./intervalCalculator"
import makeExecutor from "./makeExecutor"
import { defaultSessionProcessFunc } from "./openinsights/defaultSessionProcessFunc"
import { PerformanceEntryManager } from "./openinsights/resourceTiming"
import { Executable } from "./openinsights/start"
import { PlatformTestObject } from "./platformTestObject"
import { ResettableEventCounter } from "./resettableEventCounter"
import { RuntimeValues } from "./runtimeValues"

export type Dependencies = {
  applicationEvents: ApplicationEvents
  callClearTimeout: (depID: number, timeoutID: number) => void
  callFetch: (url: string, options?: RequestInit) => Promise<Response>
  callSendBeacon: (depID: number, url: string) => boolean
  callSetTimeout: (depID: number, fn: () => void, timeout: number) => number
  callClearResourceTimings: () => void
  /**
   * An instance of {@link Calculator} used to calculate a
   * fuzzy delay given a target frequency.
   */
  intervalCalculator: Calculator
  maxTargetFrequency: HasValue<number>
  newAbortController: () => AbortController
  newDate: (depID: number) => Date
  newEpochTimestamp: (depID: number) => number
  newMonotonicTimestamp: (depID: number) => number
  newRandomNumber: (depID: number) => number
  outputToConsole: OutputToConsoleFunc
  performanceEntryAwaitTimeouts: ResettableEventCounter
  performanceEntryManager: PerformanceEntryManager
  runtimeValues: RuntimeValues
}

type ScheduledMeasurementInfo = {
  lastScheduledAt: Date
  measureAfter: Date
}

type ScheduleRecord = {
  testObjectConfig: PlatformTestObject
  sessionMetadata: SessionMetadata
  scheduledMeasurementInfo?: ScheduledMeasurementInfo
}

export interface Scheduler {
  /**
   * Initializes the test object population and schedules measurements if necessary.
   * @param population A list of {@link PlatformTestObject} objects.
   * @param skipScheduling A set of measurements to skip scheduling. For context,
   * these are the ones that will be delegated to OpenInsights to run when the page
   * first loads. Each of those schedules a follow-up measurement when it completes.
   */
  initializePopulation(
    population: PlatformTestObject[],
    sessionMetadata: SessionMetadata,
    skipScheduling: PlatformTestObject[],
  ): void
  outputDiagnosticMessage(): void
  run(): void
  reschedule(): void
  scheduleNextMeasurement(testObjectConfig: PlatformTestObject): void
  scheduleNextMeasurementSoon(testObjectConfig: PlatformTestObject): void
  /**
   * Update the test object population after a session configuration refresh.
   */
  updatePopulation(
    population: PlatformTestObject[],
    sessionMetadata: SessionMetadata,
  ): void
}

export const createMeasurementScheduler: (
  dependencies: Dependencies,
) => Scheduler = (d) => new SchedulerImpl(d)

const newFutureMeasurementMessage = (dopplerPlatformID: string, after?: Date) =>
  `Measuring ${dopplerPlatformID} after ${after}`

const newQueuedMeasurementsLogMessage = (
  due: ScheduleRecord[],
  toQueue: ScheduleRecord[],
  now: Date,
) => {
  const getMeasurementLogLine = (scheduleRecord: ScheduleRecord) =>
    // eslint-disable-next-line max-len
    `${scheduleRecord.testObjectConfig.dopplerPlatformID} (${scheduleRecord.testObjectConfig.testObjectSize})`
  const messageBuffer = [`Measurements due at ${now}:`]
  messageBuffer.push(
    ...due.map((scheduleRecord) => getMeasurementLogLine(scheduleRecord)),
  )
  messageBuffer.push("\nMeasurements to queue:")
  messageBuffer.push(
    ...toQueue.map((scheduleRecord) => getMeasurementLogLine(scheduleRecord)),
  )
  return messageBuffer.join("\n")
}

const newDifferenceInTargetFrequencyDetectedMessage = (
  dopplerPlatformID: string,
  from: number,
  to: number,
) =>
  // eslint-disable-next-line max-len
  `Difference detected in target frequency for ${dopplerPlatformID} (from: ${from}; to: ${to})`

const newScheduleDiagnosticsMessage = (schedule: ScheduleRecord[], at: Date) =>
  schedule
    .reduce(
      (result, scheduleRecord) => {
        const scheduleInfo = scheduleRecord.scheduledMeasurementInfo
        const id = scheduleRecord.testObjectConfig.dopplerPlatformID
        result.push(
          scheduleInfo
            ? `${id} will be measured after ${scheduleInfo.measureAfter}`
            : `${id} currently unscheduled`,
        )
        return result
      },
      [`Measurement Scheduler Diagnostics as of ${at}`],
    )
    .join("\n")

const newMeasurementSkippedMessage = (dopplerPlatformID: string) =>
  `Skipping measurement for ${dopplerPlatformID} (measurements disabled)`

const newSessionConfigStalenessCheckDiagnosticMessage = (
  age: number,
  maxAge: number,
) => `Session configuration staleness check (age: ${age}, max age: ${maxAge})`

class SchedulerImpl implements Scheduler {
  /**
   * An list of records, each referencing a platform test object and information
   * about when it should be measured. Whenever it is updated, it is sorted such
   * that unscheduled items come first, followed by scheduled items in increasing
   * order of datetime due.
   */
  private _scheduleRecords: ScheduleRecord[] = []
  private _schedulerLocked = false

  constructor(private _dependencies: Dependencies) {}

  private _sortScheduleRecords() {
    this._scheduleRecords.sort((left, right) =>
      // Put unscheduled ones first
      !left.scheduledMeasurementInfo && !right.scheduledMeasurementInfo
        ? 0
        : !left.scheduledMeasurementInfo
          ? -1
          : !right.scheduledMeasurementInfo
            ? 1
            : left.scheduledMeasurementInfo.measureAfter <
                right.scheduledMeasurementInfo.measureAfter
              ? -1
              : left.scheduledMeasurementInfo.measureAfter >
                  right.scheduledMeasurementInfo.measureAfter
                ? 1
                : 0,
    )
  }

  /**
   * Called when a change occurs that may affect one or more existing scheduled
   * measurements.
   */
  reschedule(): void {
    this._dependencies.outputToConsole(
      ConsoleOutputLevel.info,
      () =>
        "Rescheduling (max target frequency " +
        this._dependencies.maxTargetFrequency.value +
        ")",
    )
    const now = this._dependencies.newDate(DepID_SchedulerReschedule)
    this._scheduleRecords.forEach((record) => {
      // This is handled similar to how updatePopulation (re-)schedules a measurement
      // when the test object configuration changes.
      const scheduleInfo = record.scheduledMeasurementInfo
      const startingFrom = scheduleInfo ? scheduleInfo.lastScheduledAt : now
      // If a measurement was already scheduled, it gets rescheduled now.
      record.scheduledMeasurementInfo = {
        lastScheduledAt: now,
        measureAfter: new Date(
          startingFrom.getTime() +
            this._dependencies.intervalCalculator.calculateInterval(
              record.testObjectConfig.targetFrequency,
            ),
        ),
      }
    })
    // Keep the schedule records sorted
    this._sortScheduleRecords()
    this.outputDiagnosticMessage()
  }

  run() {
    this._dependencies.outputToConsole(
      ConsoleOutputLevel.debug,
      () =>
        `Scheduler run ${this._dependencies.newDate(
          DepID_MeasurementSchedulerRun,
        )}`,
    )
    if (!this._schedulerLocked) {
      this._schedulerLocked = true
      const unlock = () => {
        this._schedulerLocked = false
      }
      const makeSkipMeasurementExecutor: (
        scheduler: SchedulerImpl,
        scheduleRecord: ScheduleRecord,
      ) => Executable = (
        scheduler: SchedulerImpl,
        scheduleRecord: ScheduleRecord,
      ) => {
        return {
          execute: () => {
            scheduler._dependencies.outputToConsole(
              ConsoleOutputLevel.debug,
              () =>
                newMeasurementSkippedMessage(
                  scheduleRecord.testObjectConfig.dopplerPlatformID,
                ),
            )
            scheduler.scheduleNextMeasurement(scheduleRecord.testObjectConfig)
            scheduler.outputDiagnosticMessage()
            return Promise.resolve()
          },
        }
      }
      const sessionConfigIsStale = (
        now: Date,
        lastUpdatedAt: Date,
        maxAgeInMilliseconds: number,
      ) => {
        const diffInMilliseconds = now.valueOf() - lastUpdatedAt.valueOf()
        this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
          newSessionConfigStalenessCheckDiagnosticMessage(
            diffInMilliseconds,
            maxAgeInMilliseconds,
          ),
        )
        return diffInMilliseconds > maxAgeInMilliseconds
      }
      const now = this._dependencies.newDate(DepID_MeasurementSchedulerRun)
      const sessionConfigLastUpdatedAt =
        this._dependencies.runtimeValues.sessionConfigLastUpdatedAt
      if (
        !sessionConfigLastUpdatedAt ||
        sessionConfigIsStale(
          now,
          sessionConfigLastUpdatedAt,
          // N minutes x 60 sec/min x 1000 ms/sec
          50 * 60 * 1000,
        )
      ) {
        // Put measurements on pause until after the next sessconf refresh
        this._dependencies.outputToConsole(
          ConsoleOutputLevel.info,
          () => "Measurements paused",
        )
        unlock()
        return
      }
      const measurementsDue = this._scheduleRecords.filter(
        (schedulerRecord) =>
          schedulerRecord.scheduledMeasurementInfo &&
          now > schedulerRecord.scheduledMeasurementInfo.measureAfter,
      )
      if (measurementsDue.length) {
        // Cap the number of measurements per interval
        const maxMeasurementsPerInterval = 3
        const measurementsToQueue = measurementsDue.slice(
          0,
          maxMeasurementsPerInterval,
        )
        this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
          newQueuedMeasurementsLogMessage(
            measurementsDue,
            measurementsToQueue,
            now,
          ),
        )
        defaultSessionProcessFunc(
          measurementsToQueue.map((scheduleRecord) =>
            this._dependencies.runtimeValues.measurementsEnabled
              ? makeExecutor(
                  {
                    applicationEvents: this._dependencies.applicationEvents,
                    callClearTimeout: this._dependencies.callClearTimeout,
                    callSendBeacon: this._dependencies.callSendBeacon,
                    callSetTimeout: this._dependencies.callSetTimeout,
                    callClearResourceTimings:
                      this._dependencies.callClearResourceTimings,
                    callFetch: this._dependencies.callFetch,
                    maxTargetFrequency: this._dependencies.maxTargetFrequency,
                    measurementScheduler: this,
                    newAbortController: this._dependencies.newAbortController,
                    newDate: this._dependencies.newDate,
                    newEpochTimestamp: this._dependencies.newEpochTimestamp,
                    newMonotonicTimestamp:
                      this._dependencies.newMonotonicTimestamp,
                    newRandomNumber: this._dependencies.newRandomNumber,
                    outputToConsole: this._dependencies.outputToConsole,
                    performanceEntryAwaitTimeouts:
                      this._dependencies.performanceEntryAwaitTimeouts,
                    performanceEntryManager:
                      this._dependencies.performanceEntryManager,
                    runtimeValues: this._dependencies.runtimeValues,
                  },
                  scheduleRecord.testObjectConfig,
                  scheduleRecord.sessionMetadata,
                )
              : makeSkipMeasurementExecutor(this, scheduleRecord),
          ),
        ).then(unlock, unlock)
      } else {
        unlock()
      }
    } else {
      this._dependencies.outputToConsole(
        ConsoleOutputLevel.debug,
        () => "Skipping run because the scheduler is locked",
      )
    }
  }

  private _scheduleMeasurement(
    testObjectConfig: PlatformTestObject,
    immediate: boolean,
    depID: number,
  ) {
    const record = this._scheduleRecords.find((scheduleRecord) =>
      matchTestObjectConfigIDs(
        scheduleRecord.testObjectConfig,
        testObjectConfig,
      ),
    )
    if (record) {
      const now = this._dependencies.newDate(depID)
      const when = immediate
        ? now
        : new Date(
            now.getTime() +
              // Calculate delay in milliseconds
              this._dependencies.intervalCalculator.calculateInterval(
                record.testObjectConfig.targetFrequency,
              ),
          )
      record.scheduledMeasurementInfo = {
        lastScheduledAt: now,
        measureAfter: when,
      }
      // Keep the schedule records sorted
      this._sortScheduleRecords()
      const scheduleInfo = record.scheduledMeasurementInfo
      if (scheduleInfo) {
        this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
          newFutureMeasurementMessage(
            testObjectConfig.dopplerPlatformID,
            scheduleInfo.measureAfter,
          ),
        )
      }
    }
  }

  scheduleNextMeasurement(testObjectConfig: PlatformTestObject) {
    this._scheduleMeasurement(
      testObjectConfig,
      false,
      DepID_SchedulerScheduleNextMeasurement,
    )
  }

  scheduleNextMeasurementSoon(testObjectConfig: PlatformTestObject) {
    this._scheduleMeasurement(
      testObjectConfig,
      true,
      DepID_SchedulerScheduleNextMeasurementSoon,
    )
  }

  initializePopulation(
    population: PlatformTestObject[],
    sessionMetadata: SessionMetadata,
    skipScheduling: PlatformTestObject[],
  ) {
    const skipSet = new Set(skipScheduling)
    this._scheduleRecords.push(
      ...population.map((testObjectConfig) => ({
        testObjectConfig,
        sessionMetadata,
      })),
    )
    const now = this._dependencies.newDate(DepID_SchedulerInitializePopulation)
    this._scheduleRecords.forEach((schedulerRecord) => {
      const testObjectConfig = schedulerRecord.testObjectConfig
      if (!skipSet.has(testObjectConfig)) {
        const delayInMilliseconds =
          this._dependencies.intervalCalculator.calculateInterval(
            testObjectConfig.targetFrequency,
          )
        schedulerRecord.scheduledMeasurementInfo = {
          lastScheduledAt: now,
          // Not a bug since this invocation of new Date is deterministic
          measureAfter: new Date(now.getTime() + delayInMilliseconds),
        }
        const scheduleInfo = schedulerRecord.scheduledMeasurementInfo
        if (scheduleInfo) {
          this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
            newFutureMeasurementMessage(
              testObjectConfig.dopplerPlatformID,
              scheduleInfo.measureAfter,
            ),
          )
        }
      }
    })
    // Keep the schedule records sorted
    this._sortScheduleRecords()
  }

  /**
   * Update the test object population after a session configuration refresh.
   */
  updatePopulation(
    population: PlatformTestObject[],
    sessionMetadata: SessionMetadata,
  ) {
    const calculateDelayInMilliseconds = (targetFrequency: number) =>
      this._dependencies.intervalCalculator.calculateInterval(targetFrequency)
    // Remove test objects no longer configured
    this._scheduleRecords = this._scheduleRecords.filter((scheduleRecord) =>
      population.some((incomingTestObjectConfig) =>
        matchTestObjectConfigIDs(
          incomingTestObjectConfig,
          scheduleRecord.testObjectConfig,
        ),
      ),
    )
    const now = this._dependencies.newDate(DepID_SchedulerUpdatePopulation)
    population.forEach((incomingTestObjectConfig) => {
      const record = this._scheduleRecords.find((scheduleRecord) =>
        matchTestObjectConfigIDs(
          scheduleRecord.testObjectConfig,
          incomingTestObjectConfig,
        ),
      )
      if (record) {
        // Update the existing record's configuration but only change
        // the scheduled information if the target frequency has changed
        const targetFrequencyChanged =
          record.testObjectConfig.targetFrequency !==
          incomingTestObjectConfig.targetFrequency
        if (targetFrequencyChanged) {
          this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
            newDifferenceInTargetFrequencyDetectedMessage(
              incomingTestObjectConfig.dopplerPlatformID,
              record.testObjectConfig.targetFrequency,
              incomingTestObjectConfig.targetFrequency,
            ),
          )
        }
        record.testObjectConfig = incomingTestObjectConfig
        record.sessionMetadata = sessionMetadata
        const scheduleInfo = record.scheduledMeasurementInfo
        if (!scheduleInfo || (scheduleInfo && targetFrequencyChanged)) {
          const startingFrom = scheduleInfo ? scheduleInfo.lastScheduledAt : now
          record.scheduledMeasurementInfo = {
            lastScheduledAt: now,
            measureAfter: new Date(
              startingFrom.getTime() +
                calculateDelayInMilliseconds(
                  incomingTestObjectConfig.targetFrequency,
                ),
            ),
          }
        }
      } else {
        this._scheduleRecords.push({
          testObjectConfig: incomingTestObjectConfig,
          sessionMetadata,
          scheduledMeasurementInfo: {
            lastScheduledAt: now,
            measureAfter: new Date(
              now.getTime() +
                calculateDelayInMilliseconds(
                  incomingTestObjectConfig.targetFrequency,
                ),
            ),
          },
        })
      }
    })
    // Keep the schedule records sorted
    this._sortScheduleRecords()
    this.outputDiagnosticMessage()
  }

  outputDiagnosticMessage() {
    this._dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
      newScheduleDiagnosticsMessage(
        this._scheduleRecords,
        this._dependencies.newDate(DepID_SchedulerOutputDiagnosticsMessage),
      ),
    )
  }
}

function matchTestObjectConfigIDs(
  first: PlatformTestObject,
  second: PlatformTestObject,
) {
  return (
    first.dopplerPlatformID === second.dopplerPlatformID &&
    first.testObjectSize === second.testObjectSize
  )
}
