import { EndpointsSource } from "../@types"
import { PageSettings } from "./pageSettings"

/**
 * Endpoints obtained from the session configuration response.
 */
export type DopplerEndpoints = {
  /**
   * The endpoint for Resource Timing beacons.
   */
  dopplerResourceTimingEndpoint: string
}

export interface Endpoints {
  applicationEventsBase: string | null
  dopplerEndpoints: DopplerEndpoints
  pulsarEndpointBase: string
}

export const createEndpointsConfig: (
  source: EndpointsSource,
  pageSettings: PageSettings,
) => Endpoints = (s, p) => new EndpointsImpl(s, p)

class EndpointsImpl implements Endpoints {
  private _applicationEventsBase
  private _dopplerEndpoints: DopplerEndpoints
  private _pulsarEndpointBase

  constructor(source: EndpointsSource, pageSettings: PageSettings) {
    /* eslint-disable @typescript-eslint/dot-notation */
    this._applicationEventsBase =
      pageSettings.applicationLoggingEndpoint ||
      source["application_events_base"]
    this._dopplerEndpoints = {
      // Provide a default until the session config file includes this
      // endpoint.
      dopplerResourceTimingEndpoint:
        pageSettings.dopplerBeaconEndpoint ||
        source["doppler"]["resource_timing"],
    }
    this._pulsarEndpointBase = source["pulsar"]
    /* eslint-enable @typescript-eslint/dot-notation */
  }

  get applicationEventsBase() {
    return this._applicationEventsBase
  }

  get dopplerEndpoints() {
    return this._dopplerEndpoints
  }

  get pulsarEndpointBase() {
    return this._pulsarEndpointBase
  }
}
