import { ConfigSettings3 } from "./config3"
import { PageSettings } from "./pageSettings"

export interface SiteOwnerSettings {
  applicationEventsBeaconIntervalInMilliseconds: number
  dnsClientInfoBaseHostname: string
  /**
   * The percentage of session config requests that should be accompanied
   * by a resolver geo sample. 0 means never. 1 means always.
   */
  dnsClientInfoSamplePercentage: number
  /**
   * The maximum number of test objects to measure initially. It might also
   * make sense to use this to limit the size of batches submitted by the
   * measurement scheduler.
   */
  maxInitialTestObjects: number
  /**
   * Measurement start delay in seconds
   */
  measurementStartDelay: number
  /**
   * Time to wait between measurement scheduler checks, when any measurements
   * _due_ are scheduled to be run as soon as possible. Lower values allow
   * measurements to be more spread out. Higher values cause more batching.
   */
  schedulerRunIntervalInMilliseconds: number
  /**
   * The time to wait between session config downloads.
   */
  sessionRefreshIntervalInMilliseconds: number
  networksDisallowed: number[]
}

export const createSiteOwnerSettings: (
  settingsSource: ConfigSettings3,
  pageSettings: PageSettings,
) => SiteOwnerSettings = (s, p) => new SiteOwnerSettingsImpl(s, p)

class SiteOwnerSettingsImpl implements SiteOwnerSettings {
  private _applicationEventsBeaconIntervalInMilliseconds
  private _dnsClientInfoBaseHostname
  private _dnsClientInfoSamplePercentage
  private _maxInitialTestObjects
  private _measurementStartDelay
  private _networksDisallowed
  private _schedulerRunIntervalInMilliseconds
  private _sessionRefreshIntervalInMilliseconds
  constructor(settingsSource: ConfigSettings3, pageSettings: PageSettings) {
    /* eslint-disable @typescript-eslint/dot-notation */
    this._applicationEventsBeaconIntervalInMilliseconds = secondsToMilliseconds(
      settingsSource["a"],
      pageSettings.applicationEventsBeaconInterval,
    )
    this._dnsClientInfoBaseHostname = settingsSource["dcibh"]
    this._dnsClientInfoSamplePercentage = settingsSource["dcisp"]
    this._maxInitialTestObjects = settingsSource["m"]
    this._measurementStartDelay = settingsSource["st"]
    this._sessionRefreshIntervalInMilliseconds = secondsToMilliseconds(
      settingsSource["c"],
      pageSettings.sessionRefreshInterval,
    )
    this._schedulerRunIntervalInMilliseconds = secondsToMilliseconds(
      settingsSource["sc"],
      pageSettings.schedulerRunInterval,
    )
    this._networksDisallowed = settingsSource["n"] || []
    /* eslint-enable @typescript-eslint/dot-notation */
  }

  get networksDisallowed() {
    return this._networksDisallowed
  }

  get dnsClientInfoBaseHostname() {
    return this._dnsClientInfoBaseHostname
  }

  get dnsClientInfoSamplePercentage() {
    return this._dnsClientInfoSamplePercentage
  }

  get applicationEventsBeaconIntervalInMilliseconds() {
    return this._applicationEventsBeaconIntervalInMilliseconds
  }

  get maxInitialTestObjects() {
    return this._maxInitialTestObjects
  }

  get measurementStartDelay() {
    return this._measurementStartDelay
  }

  get sessionRefreshIntervalInMilliseconds() {
    return this._sessionRefreshIntervalInMilliseconds
  }

  get schedulerRunIntervalInMilliseconds() {
    return this._schedulerRunIntervalInMilliseconds
  }
}

function secondsToMilliseconds(
  sessionConfigSetting: number,
  pageLevelSetting?: number,
) {
  const setting =
    typeof pageLevelSetting === "number"
      ? pageLevelSetting
      : sessionConfigSetting
  return setting * 1000
}
